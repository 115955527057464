export const resources = {
  en: {
    common: {
      oldClient: {
        openChat: "Open Chat",
        closeChat: "Close Chat",
        selfAudioTest: "Self Audio Test",
        failGeoInfo: "Fail to get GeoInfo! Question will be disabled!",
        unifiedPlanNotSupported: "WebRTC Unified Plan is NOT supported",
        browserNotSupported: "Browser not supported",
        videoNotDetected: "Video device not detected!",
        noInputDevices: " :: No input devices found ::",
        recording: "Recording...",
        playing: "Playing...",
        networkSettingsChanged: "Lost connection to the server!",
        serverStoppedReceiveOurMedia: "The server stopped receiving your video! Pls check your camera.",
        serverStoppedReceiveOurAudio: "The server stopped receiving your audio! Pls check your mic.",
        maxUsersInRoom: "Max number of users in this room was reached",
        error: "Error: ",
        popupBlock: "You browser blocks our popup! You need to allow it",
        yourName: "Enter your name...",
        selectRoom: "Select Room:",
        askQuestion: "Ask a Question",
        cancelQuestion: "Cancel the Question",
        openBroadcast: "Display Broadcast",
        closeBroadcast: "Hide Broadcast",
        openFullScreen: "Maximize to full screen",
        closeFullScreen: "Exit full screen",
        broadcast: "Broadcast",
        layout: "Layout",
        mute: "Mute",
        unMute: "Unmute",
        startVideo: "Start Video",
        stopVideo: "Stop Video",
        audioMode: "Audio Mode",
        fullMode: "Full Mode",
        selectDevice: "Select device:",
        videoSettings: "Video Settings:",
        noResultsFound: "No results found.",
        settings: "Settings",
        vote: "Vote",
        streamForWorkshop: "STREAM FOR WORKSHOP",
        streamForWorkshopDescription: "(focus group is muted)",
        sourceStream: "SOURCE STREAM",
        sourceStreamDescription: "(focus group is on)",
        dualLnaguagesStream: "DUAL LANGUAGES STREAM",
        dualLnaguagesStreamDescription: "(focus group is muted)",
        lowQuality: "Low quality",
        mediumQuality: "Medium quality",
        highQuality: "High quality",
        low: "Low",
        medium: "Medium",
        high: "High",
        noVideo: "No Video",
        audioOnly: "Audio only",
        profile: "Profile:",
        myProfile: "My Profile",
        myAccount: "My Account",
        signOut: "Sign Out",
        homerLimud: "Study Material",
        video: "Video",
        audio: "Audio",
        language: "Language",
        joinRoom: "Join Room",
        drawing: "Sketches",
        material: "Study Materials",
        chat: "Chat",
        support: "Support",
        donate: "DONATE",
        sendQuestion: "Send a question",
        user: "User",
        usefulLinks: "Useful Links",
        leave: "Leave",
        SvivaTova: "Sviva Tova",
        LiveGroups: "Live Groups",
        WorkshopQuestions: "Workshop Questions",
        KabbalahMedia: "Kabbalah Media",
        openKliOlami: "Display Groups",
        closeKliOlami: "Hide Groups",
        oldDesign: "OLD VERSION",
        newDesign: "NEW VERSION",
        darkTheme: "Dark Theme",
        moveToNewVersion: `Please note that this version of Arvut system will stop it’s operation on the February 28th 2022. \n Please move to the new version. If you have any troubles with it, contact the support team.`,
        hideDisplays: "Hide users names",
        shidurMuted: "Mute broadcast",
        translationOn: "Translation On",
        translationOff: "translation Off",
      },
      settings: {
        selectCamera: "Select camera",
        selectMic: "Select microphone",
        selectSize: "Select camera quality",
        userSettings: "User settings",
        screenName: "Screen name",
        interfaceLanguage: "Interface language",
        subtitlesSettings: "Subtitles settings",
        subtitleLanguage: "Source texts",
        workshopLanguage: "Workshop questions",
        broadcastQuality: "Broadcast quality",
        broadcastLanguage: "Broadcast language",
        cameraSettings: "Camera settings",
        microphoneSettings: "Microphone settings",
        micSource: "Audio source",
        cameraSource: "Camera source",
        cameraQuality: "Camera quality",
        helloUser: "Hello {{name}}",
        beforeConnecting: "before connecting with the TEN, please check your settings and device setup:",
        backToTen: "BACK TO TEN",
        donationHeader: "Dear Friends,",
        donationBtn: "To participate in the donation >>",
        donationContent: `
        <p>
            A moment before you enter the ten room for the next Kabbalah lesson, just before you smile at your friends, read source excerpts with them, ask Rav Laitman a question and hold a workshop, we want to make a request to you.        </p>
        <p>
            To date, we have distributed unprecedented amounts of valuable and unique materials all over the world, and it's all thanks to you, thousands of loyal volunteers. But it's not enough.
        </p>
        <p>
            Day by day the world falls into a more acute crisis in all areas of life, and it needs contents that only our organization can provide; Contents that explain the importance of connection between people and bring them closer to the solution for all crises.       </p>
        <p>
            This is why now, more than ever, we invite you to donate and participate in increasing the dissemination of our contents to humanity.
        </p>
        <p>
            The management of the "Bnei Baruch - Kabbalah La’Am" association.
        </p>`
      },
      feedback: {
        feedbackHelp: "Feedback/Help",
        feedback: "Feedback",
        help: "Help",
      },
      virtualChat: {
        roomChat: "Room chat",
        supportChat: "Support chat",
        send: "Send",
        enterMessage: "Type your message",
        msgRoomInfo: "Messages here will be seen by all the members of the ten connected now.",
        msgAdminInfo: "Our support team will contact you here.",
        moderator:
          "Moderator: Selected questions related to the theam of the lesson will be asked during the broadcast",
      },
      questions: {
        enterQuestion: "My question is...",
        userName: "My name",
        galaxyRoom: "Ten name",
        sendQuestion: "SEND A QUESTION",
      },
      loginPage: {
        support: "Help",
        galaxy: "Arvut",
        slogan: "The Group Today Is You Tomorrow",
        regUsers: "Registered Users",
        newUsers: "New Users",
        register: "Register",
        guestMessage1: "You can either ",
        guestMessage2: "or continue using the system as guest.",
        login: "Login",
        guest: "Continue as a Guest",
        welcomeUser: "Welcome, [UserFirstName]",
        logoOurConnection: "OUR CONNECTION",
        logoNetwork: "NETWORK",
        intro:
          "Our Virtual Home, developed by the Bnei Baruch Kabbalah La’am Association, is a worldwide headquarters and virtual space for spiritual advancement, providing an interactive environment that feels like physical learning—watch live lessons, ask questions and receive real-time answers, conduct workshop discussions, and listen to a wide selection of translations.",
        userFee: "Pay User Fee",
      },
      loginMessage: {
        register: "register",
        register2: "register",
        login: "LOGIN",
        loginWarning1:
          "You are using the system as a guest. In order to help us  keep the system secured we kindly ask you to ",
        loginWarning2: "and start using it safely. If you already did it just click the login button.",
        popupMessage1: "Please note, as of 1st of May 2020, the system will no longer allow guest access, please ",
        popupMessage2: "and help us maintain a secure system.",
      },
      galaxyApp: {
        pendingApproval:
          "Thank you for registering to Arvut. Your account will be verified in the next few days. You will be able to use the system afterwards.",
        terms: "Arvut Terms of Use",
        verifyAccount:
          "You successfully registered to Arvut. We will need to verify your account with one of your friends. Please provide an email address of a friend that can verify he knows you. If you don’t have such information right now, you will be granted partial guest access.",
        typeFriendEmail: "Type the email",
        welcomeGuestForm:
          "You are now registered as a guest. To be granted full access, we need to verify your account. Please fill in an email address of a friend that can verify your acquaintance.",
        welcomeGuestPopup:
          "Welcome to Arvut. You are now registered as a guest. We will need to verify your account. In the meantime you will be granted partial access permissions: You will be able to join any room, see who is inside and watch the broadcast. You will not be able to participate in the workshops or ask questions during a live lesson. Please help us verify your account as soon as possible by filling out the form at the top of the page.",
        welcomeGuestPopupRequested:
          "Welcome to Arvut. A verification request was sent to your friend. In the meantime you will be granted partial access permissions: You will be able to join any room, see who is inside and watch the broadcast. You will not be able to participate in the workshops or ask questions during a live broadcast.",
        approveVerificationHeader:
          "Hi [UserFirstName], Your friends ask your verification. If you know them and can verify their email please check them as verified on the list below so they can start using Arvut. If you are not familiar with them check ignore:",
        approveVerificationVerify: "Verify",
        approveVerificationIgnore: "Ignore",
        approveVerificationEmail: "Email",
        requestedVerificationBadEmailPopup:
          "The email you entered is not registered to Arvut. Please enter a valid email at the form above.",
        requestedVerificationPopup:
          "A verification request was sent to your friend. Upon verification, you will be granted full access to Arvut.",
        ok: "OK",
        close: "Close",
        cancel: "Cancel",
        continueAsGuest: "Continue as a Guest",
        hideSelfView: "Hide self view",
        showSelfView: "Show self view"
      },
      workshop: {
        hideQuestion: "Hide question",
        showQuestion: "Show question",
        copyQuestion: "Copy question",
        manageFontSize: "Manage font size",
        inProcess: "In process... Available in",
      },
      registration: {
        continueAsGuest: "Continue as a guest",
        selectCountry: "Country",
        groupCity: "City",
        selectGender: "Gender",
        selectLanguage: "Language",
        selectGroup: "Ten Name",
        telephone: "Mobile Phone",
        male: "Male",
        female: "Female",
        aboutYou: "About my study framework in Bnei Baruch...",
        submit: "SUBMIT FOR APPROVAL",
        welcome: "Welcome {{name}}",
        needVerifyAccount:
          "You successfully registered to Arvut system. In order to grant you with a full access, we will need to verify your account.",
        tellAboutYou: "Please tell us about you:",
        asGuestYouCan:
          "As a guest, you will be able to watch the broadcast. You won’t be able to participate in the workshops or ask questions live.",
        youRegisteredAsGuest:
          "You are now registered as a guest. To be granted full access, we need to verify your account. ",
        youRegisteredAsGuestLink: "Please complete the registration.",
        toComplete: "TO COMPLETE REGISTRATION",
        completed: "Your registration is completed and will be verified soon. Meanwhile you can watch the broadcast.",
      },
      temp: {
        linkToCongress: "convention site",
      },
    },
  },
  es: {
    common: {
      oldClient: {
        openChat: "Abrir chat",
        closeChat: "Cerrar chat",
        selfAudioTest: "Auto test de audio",
        failGeoInfo: "No se puede obtener GeoInfo! ¡La pregunta se desactivará!",
        unifiedPlanNotSupported: "El plan unificado de WebRTC NO es compatible",
        browserNotSupported: "Navegador no compatible",
        videoNotDetected: "¡Dispositivo de video no detectado!",
        noInputDevices: ":: No se encontraron dispositivos de entrada ::",
        recording: "Grabando ...",
        playing: "Funcionando ...",
        networkSettingsChanged: "¡La configuración de red ha cambiado!",
        serverStoppedReceiveOurMedia:
          "¡El servidor ha dejado de recibir nuestro video! Revise su dispositivo de video. ",
        serverStoppedReceiveOurAudio: "¡El servidor se ha dejado de recibir nuestro audio! Revise su micrófono.",
        maxUsersInRoom: "Se ha llegado al número máximo de usuarios en esta sala",
        error: "Error: ",
        popupBlock: "¡Su navegador bloquea nuestra ventana emergente! Debe autorizarlo ",
        yourName: "Introduzca su nombre...",
        selectRoom: "Seleccionar sala:",
        askQuestion: "Hacer una pregunta",
        cancelQuestion: "Cancelar la pregunta",
        openBroadcast: "Mostrar transmisión",
        closeBroadcast: "Ocultar emisión",
        openFullScreen: "Maximizar a pantalla completa",
        closeFullScreen: "Salir de pantalla completa",
        broadcast: "Transmisión",
        layout: "Layout",
        mute: "Silenciar",
        unMute: "Reactivar sonido",
        startVideo: "Iniciar video",
        stopVideo: "Detener video",
        audioMode: "Modo de audio",
        fullMode: "Modo completo",
        selectDevice: "Seleccione el dispositivo:",
        videoSettings: "Ajustes de video:",
        noResultsFound: "No se han encontrado resultados.",
        settings: "Configuración",
        vote: "Votar",
        streamForWorkshop: "Retransmisión para taller",
        streamForWorkshopDescription: "(grupo de enfoque silenciado)",
        sourceStream: "Retransmisión original",
        sourceStreamDescription: "(Grupo de enfoque activado)",
        dualLnaguagesStream: "Retransmisión dual de idiomas",
        dualLnaguagesStreamDescription: "(Grupo de enfoque silenciado)",
        lowQuality: "Baja calidad",
        mediumQuality: "Calidad media",
        highQuality: "Alta calidad",
        low: "Baja",
        medium: "Media",
        high: "Alta",
        noVideo: "Sin vídeo",
        audioOnly: "Solo audio",
        profile: "Perfil:",
        myProfile: "Mi perfil",
        myAccount: "Mi cuenta",
        signOut: "Desconectar",
        homerLimud: "Material de estudio",
        video: "Vídeo",
        audio: "Audio",
        cameraQuality: "Calidad de la cámara",
        language: "Idioma",
        joinRoom: "Unirse a la sala",
        drawing: "Gráficos",
        material: "Material de estudio",
        chat: "Chat",
        support: "Soporte",
        donate: "DONAR",
        sendQuestion: "Enviar una pregunta",
        user: "Usuario",
        usefulLinks: "Enlaces útiles",
        leave: "Salir",
        SvivaTova: "Sviva Tova",
        LiveGroups: "Grupos en vivo",
        WorkshopQuestions: "Preguntas del taller",
        KabbalahMedia: "Cabalá media",
        openKliOlami: "Mostrar grupos",
        closeKliOlami: "Ocultar grupos",
        oldDesign: "VIEJA VERSION",
        newDesign: "NUEVA VERSIÓN",
        darkTheme: "Tema oscuro",
        moveToNewVersion: `Please note that this version of Arvut system will stop it’s operation on the February 28th 2022. \n Please move to the new version. If you have any troubles with it, contact the support team.`,
        hideDisplays: "Ocultar nombres de usuarios",
        shidurMuted: "Silenciar el audio de la transmisión",
        translationOn: "Traducción activ.",
        translationOff: "Traducción desactiv.",
      },
      settings: {
        selectCamera: "Select camera",
        selectMic: "Select microphone",
        selectSize: "Elegir la calidad de la cámara",
        userSettings: "Material de estudio",
        screenName: "Nombre en pantalla",
        interfaceLanguage: "Idioma de la interface",
        subtitlesSettings: "Configuración de subtítulos",
        subtitleLanguage: "Texto de las fuentes",
        workshopLanguage: "Preguntas del taller",
        broadcastQuality: "Calidad de la transmisión",
        broadcastLanguage: "Idioma de transmisión",
        cameraSettings: "Configuración de la cámara",
        microphoneSettings: "Configuración del micrófono",
        micSource: "Fuente de audio",
        cameraSource: "Fuente de cámara",
        cameraQuality: "Calidad de la cámara",
        helloUser: "Hola {{name}}",
        beforeConnecting:
          "Antes de conectarte con la DECENA, por favor chequea tus configuraciones y la del dispositivo:",
        backToTen: "VOLVER A DECENA",
        donationHeader: "Queridos amigos y amigas,",
        donationBtn: "Para participar en la donación >>",
        donationContent: `
        <p>
        Justo antes de entrar a la sala de la decena para la próxima clase de Cabalá, leer extractos de las fuentes originales con ellos, hacerle una pregunta al Rav Laitman y realizar un taller, queremos hacerles una solicitud.
        </p>
        <p>
        Hasta la fecha, hemos difundido cantidades sin precedentes de material valioso y único en todo el mundo, y todo gracias a ustedes, miles de fieles voluntarios.
        </p>
        <p>
        Pero no es suficiente. Día a día el mundo cae en una crisis más aguda en todos los ámbitos de la vida, y necesita contenidos que solo nuestra organización puede brindar; contenidos que explican la importancia de una buena relación entre las personas y las acerca a la solución de todas las crisis.
        </p>
        <p>
        Por eso ahora, más que nunca, les invitamos a donar económicamente y a participar en la expansión de la difusión de nuestro contenido a la humanidad.
        </p>
        <p>
        Dirección de la Asociación Bnei Baruj
        </p>`
      },
      feedback: {
        feedbackHelp: "Comentarios/Ayuda",
        feedback: "Comentarios",
        help: "Ayuda",
      },
      virtualChat: {
        roomChat: "Sala de chat",
        supportChat: "Chat de soporte",
        send: "Enviar",
        enterMessage: "Escriba su mensaje",
        msgRoomInfo:
          "Los mensajes de aquí serán vistos por todos los miembros de la decena que están conectados ahora.",
        msgAdminInfo: "Nuestro equipo de soporte te contactará aquí",
      },
      questions: {
        enterQuestion: "Mi pregunta es...",
        userName: "Mi nombre",
        galaxyRoom: "Nombre de decena",
        sendQuestion: "Enviar una pregunta",
      },
      loginPage: {
        support: "Ayuda",
        galaxy: "Arvut",
        slogan: "El grupo hoy eres tú mañana",
        regUsers: "Usuarios registrados",
        newUsers: "Nuevos usuarios",
        register: "Registrarse",
        guestMessage1: "Puede ",
        guestMessage2: "o continuar usando el sistema como invitado.",
        login: "Ingresar",
        guest: "Continuar como invitado",
        welcomeUser: "Hola, [UserFirstName]",
        logoOurConnection: "NUESTRA RED",
        logoNetwork: "DE CONEXIÓN",
        intro:
          "Nuestro Hogar Virtual, nuestra sede mundial, el lugar para nuestro avance espiritual, es nuestro lugar común construido y desarrollado por la Asociación Bnei Baruj Kabbalah Laam como un centro educativo global que nos permite experimentar un entorno de aprendizaje interactivo lo más cercano posible al aprendizaje físico: ver lecciones en vivo, hacer preguntas y recibir respuestas en tiempo real, realizar talleres y escuchar una amplia selección de traducciones.",
        userFee: "Pagar tarifa de usuario",
      },
      loginMessage: {
        register: "registre",
        register2: "registrarse",
        login: "INGRESAR",
        loginWarning1:
          "Está usando el sistema como invitado (guest). Para poder ayudarnos a mantener la seguridad del sistema, le solicitamos amablemente que se ",
        loginWarning2: 'y comience a usarlo de forma segura. Si ya lo ha hecho solo presiona el botón "Ingresar".',
        popupMessage1:
          "Tenga en cuenta, a partir del 1 de de mayo de 2020, el sistema ya no permitir el acceso de invitados, por favor, ",
        popupMessage2: "y nos ayudan a mantener un sistema seguro.",
      },
      galaxyApp: {
        pendingApproval:
          "Gracias por registrarte en Arvut. Su cuenta será verificada en los próximos días. Podrá utilizar el sistema después.",
        terms: "Términos de uso de Arvut",
        close: "Cerrar",
        verifyAccount:
          "Se registro al Arvut con éxito. Necesitamos verificar su cuenta con uno de sus amigos que pueda confirmar que le conoce. Complete a continuación el correo electrónico de un amigo que pueda confirmarlo. Si no tiene esta información, se le dará acceso parcial al sistema.",
        typeFriendEmail: "Escriba el correo electonico de su amigoEscriba el correo electonico de su amigo",
        welcomeGuestForm:
          "Actualmente está registrado como invitado. Para obtener acceso completo, debemos verificar su cuenta. Complete la dirección de correo electrónico de un amigo que pueda verificar que lo conoce.",
        welcomeGuestPopup:
          "Bienvenido a Arvut. Ahora está registrado como invitado. Tendremos que verificar su cuenta. Mientras tanto, se le otorgará permiso de acceso parcial: podrá unirse a cualquier sala, ver quién está adentro y ver la transmisión. No podrá participar en los talleres o hacer preguntas durante una lección en vivo, ayúdenos a verificar su cuenta lo antes posible completando el formulario en la parte superior de la página.",
        welcomeGuestPopupRequested:
          "Bienvenido a Arvut. Se envió un mensaje de solicitud, para verificar su cuenta, a su amigo. Hasta que se apruebe, recibirá permiso parcial para el sistema: puede unirse a cualquier sala, ver quién está en la sala y ver la transmisión. No podrá participar en el taller, ni hacer preguntas durante la transmisión en vivo.",
        approveVerificationHeader:
          "Hola [UserFirstName],´tus amigos te piden verificación. Si los conoces y puedes verificar su correo electrónico, por favor márcalos como verificados en la lista, para que ellos puedan comenzar a utilizar Arvut. Si no los conoces marca ignorar:",
        approveVerificationVerify: "Verificar",
        approveVerificationIgnore: "Ignorar",
        approveVerificationEmail: "Email",
        requestedVerificationBadEmailPopup:
          "El correo electrónico que ingresó no está registrado en Arvut. Ingrese un correo electrónico válido en el formulario de arriba.",
        requestedVerificationPopup:
          "Se envió un correo electrónico de verificación a su amigם. Después de la verificación, tendrá acceso completo al sistema Arvut.",
        ok: "OK",
        cancel: "Cancel",
        continueAsGuest: "Continuar como invitado",
        hideSelfView: "Ocultar vista propia",
        showSelfView: "Mostrar visión propia"
      },
      registration: {
        continueAsGuest: "Continuar como invitado",
        selectCountry: "País",
        groupCity: "Ciudad",
        selectGender: "Género",
        selectLanguage: "Idioma",
        selectGroup: "Nombre de decena",
        telephone: "Teléfono móvil",
        male: "Hombre",
        female: "Mujer",
        aboutYou: "Acerca de mi marco de estudio en Bnei Baruj",
        submit: "Enviar para aprobación",
        welcome: "Bienvenido/a {{name}}",
        needVerifyAccount:
          "Te has inscrito exitosamente al sistema Arvut. Para proveerte acceso completo precisamos verificar tu cuenta.",
        tellAboutYou: "Por favor cuéntanos de ti:",
        asGuestYouCan:
          "Como invitado vas a poder ver la transmisión. No podrás participar en los talleres o hacer preguntas en vivo.",
        youRegisteredAsGuest: "Estás registrado como invitado. Para darte acceso completo debemos verificar tu cuenta.",
        youRegisteredAsGuestLink: "Por favor completa tu registración.",
        toComplete: "Para completar la registración",
        completed: "Tu registración fue completada y será verificada pronto. Mientras tanto puedes ver la transmisión.",
      },
      workshop: {
        hideQuestion: "Ocultar pregunta",
        showQuestion: "Mostrar pregunta",
        copyQuestion: "Copiar pregunta",
        manageFontSize: "Cambiar el tamaño de la fuente",
        inProcess: "En proceso… Disponible en",
      },
      temp: {
        linkToCongress: "sitio del congreso",
      },
    },
  },
  he: {
    common: {
      oldClient: {
        openChat: "לפתוח צ׳אט",
        closeChat: "לסגור צ׳אט",
        selfAudioTest: "בדיקת שמיעה עצמית",
        failGeoInfo: "לא ניתן לאתר מיקום! לא יתאפשר לשאול שאלה",
        unifiedPlanNotSupported: "WebRTC Unified Plan לא נתמך",
        browserNotSupported: "הדפדפן לא נתמך",
        videoNotDetected: "לא זוהה מקור וידאו",
        noInputDevices: "::לא זוהו מקורות כניסה::",
        recording: "מקליט... ",
        playing: "מתנגן...",
        networkSettingsChanged: "הגדרות האינטרנט השתנו, אנא בצע חיבור מחדש",
        serverStoppedReceiveOurMedia: "השרת הפסיק לקבל את הוידאו שלך! אנא בדוק את מקור הוידאו שלך.",
        serverStoppedReceiveOurAudio: "השרת הפסיק לקבל את האודיו שלך! אנא בדוק את מקור האודיו שלך.",
        maxUsersInRoom: "הגענו למספר החיבורים המקסימלי בחדר 16",
        error: "קרתה שגיאה: ",
        popupBlock: "הדפדפן שלך חוסם את הפופאפ שלנו! אנא אשר  אותו",
        yourName: "הכנס שמך...",
        selectRoom: "בחר/י חדר",
        askQuestion: "לשאול שאלה",
        cancelQuestion: "לבטל שאלה",
        openBroadcast: "הצג שידור",
        closeBroadcast: "הסתר שידור",
        openFullScreen: "להגדיל למסך מלא",
        closeFullScreen: "לצאת ממסך מלא",
        broadcast: "שידור",
        layout: "פריסה",
        mute: "להשתיק",
        unMute: "לבטל השתקה",
        startVideo: "להתחיל וידאו",
        stopVideo: "להפסיק וידאו",
        audioMode: "מצב שמע",
        fullMode: "מצב מלא",
        selectDevice: "בחר מקור כניסה",
        videoSettings: "הגדרות וידאו",
        noResultsFound: "לא נמצאו תוצאות",
        settings: "הגדרות",
        vote: "להצביע",
        streamForWorkshop: "ערוץ לסדנה",
        streamForWorkshopDescription: "(קבוצת המיקוד מושתקת)",
        sourceStream: "ערוץ מקור",
        sourceStreamDescription: "(קבוצת המיקוד פועלת)",
        dualLnaguagesStream: "ערוץ שפה כפולה",
        dualLnaguagesStreamDescription: "(קבוצת המיקוד מושתקת)",
        lowQuality: "איכות נמוכה",
        mediumQuality: "איכות בינונית",
        highQuality: "איכות גבוה",
        low: "נמוכה",
        medium: "בינונית",
        high: "גבוה",
        noVideo: "ללא וידיאו",
        audioOnly: "שמע בלבד",
        profile: "פרופיל:",
        myProfile: "הפרופיל שלי",
        myAccount: "החשבון שלי",
        signOut: "יציאה",
        homerLimud: "חומר לימוד",
        video: "וידאו",
        audio: "אודיו",
        cameraQuality: "איכות מצלמה",
        language: "שפה",
        joinRoom: "כנס לחדר",
        drawing: "שרטוטים",
        material: "חומר לימוד",
        chat: "צ׳אט",
        support: "תמיכה",
        donate: "לתרום",
        sendQuestion: "שלח שאלה",
        user: "משתמש",
        usefulLinks: "קישורים שימושיים",
        leave: "יציאה",
        SvivaTova: "סביבה טובה",
        LiveGroups: "קבוצות בלייב",
        WorkshopQuestions: "שאלות סדנא",
        KabbalahMedia: "קבלה מדיה",
        openKliOlami: "הצג קבוצות",
        closeKliOlami: "הסתר קבוצות",
        oldDesign: "גרסא ישנה",
        newDesign: "גרסא חדשה",
        darkTheme: "עיצוב כהה",
        moveToNewVersion: `שים לב! גרסה זו של מערכת Arvut תפסיק את פעילותה ב-28 בפברואר 2022.
        \n
        נא לעבור לגרסה החדשה. אם נתקלת בבעיה להשתמש בה, אנא צור קשר עם צוות התמיכה.`,
        hideDisplays: "הסתר את שמות המשתמשים",
        shidurMuted: "השתק שידור",
        translationOn: "עם תרגום",
        translationOff: "ללא תרגום",
      },
      settings: {
        selectCamera: "בחר מצלמה",
        selectMic: "בחר מיקרופון",
        selectSize: "בחר איכות של מצלמה",
        userSettings: "הגדרות משתמש",
        screenName: "כינוי להצגה",
        interfaceLanguage: "שפת הממשק",
        subtitlesSettings: "הגדרות כתוביות",
        subtitleLanguage: "טקסט מקורות",
        workshopLanguage: "שאלות סדנא",
        broadcastQuality: "איכות השידור",
        broadcastLanguage: "שפת השידור",
        cameraSettings: "הגדרות מצלמה",
        microphoneSettings: "הגדרות מיקרופון",
        micSource: "מקור אודיו",
        cameraSource: "מקור וידאו",
        cameraQuality: "איכות המצלמה",
        helloUser: "שלום {{name}}",
        beforeConnecting: "לפני החיבור לעשיריה, אנא אנא בידקו את ההגדרות שלכם.:",
        backToTen: "בחזרה לעשיריה",
        donationHeader: "חברים יקרים,",
        donationBtn: "להשתתפות בתרומה >>",
        donationContent: `
        <p>
        רגע לפני שאתם נכנסים לחדר העשירייה לשיעור הקבלה הבא, רגע לפני שתחייכו לחברים, תקראו יחד איתם קטעי מקור, תשאלו את הרב לייטמן שאלה ותערכו סדנה, אנחנו רוצים לבקש מכם בקשה.
        </p>
        <p>
        עד היום הפצנו בכל העולם כמויות חסרות תקדים של חומר ערכי וייחודי, והכול בזכותכם, אלפי מתנדבים ומתנדבות נאמנים. אבל זה לא מספיק.
        </p>
        <p>
        מיום ליום העולם נקלע למשבר חריף יותר בכל תחומי החיים, והוא זקוק לתכנים שרק הארגון שלנו מסוגל לספק; תכנים המסבירים את חשיבות הקשר בין בני אדם ומקרבים אותם לפתרון כל המשברים.
        </p>
        <p>
        לכן עכשיו, יותר מתמיד, אנחנו מזמינים אתכם לתרום כספית ולהשתתף בהגברת הפצת התכנים שלנו לאנושות.
        </p>
        <p>
        הנהלת עמותת "בני ברוך - קבלה לעם"
        </p>`
      },
      feedback: {
        feedbackHelp: "משוב/עזרה",
        feedback: "משוב",
        help: "עזרה",
      },
      virtualChat: {
        roomChat: "צ׳אט כללי",
        supportChat: "צ׳אט תמיכה",
        send: "שלח",
        enterMessage: "הקלד/י את ההודעה",
        msgRoomInfo: "ההודעות כאן  יוצגו לכל חברי העשיריה המחוברים כעת.",
        msgAdminInfo: "צוות התמיכה שלנו יצור איתכם קשר כאן.",
      },
      questions: {
        enterQuestion: "השאלה שלי היא...",
        userName: "השם שלי",
        galaxyRoom: "שם העשיריה",
        sendQuestion: "שלח שאלה",
      },
      loginPage: {
        support: "עזרה",
        galaxy: "ערבות",
        slogan: "הקבוצה היום זה אתה מחר",
        regUsers: "משתמשים רשומים",
        newUsers: "משתמשים חדשים",
        register: "להרשם",
        guestMessage1: "אתה יכול ",
        guestMessage2: "או להמשיך להשתמש במערכת כאורח.",
        login: "כניסה",
        guest: "המשך כאורח",
        welcomeUser: "שלום, [UserFirstName]",
        logoOurConnection: "מערכת",
        logoNetwork: "הקשר בינינו",
        intro:
          "הבית הווירטואלי שלנו, המטה העולמי שלנו, המקום להתקדמות הרוחנית שלנו, הוא המקום המשותף שלנו שנבנה ופותח על ידי עמותת בני ברוך קבלה לעם כמרכז חינוכי עולמי, המאפשר לנו לחוות סביבת למידה אינטראקטיבית קרובה ככל האפשר ללימוד פיזי: לצפות בשיעורים חיים, לשאול שאלות ולקבל תשובות בזמן אמת, לערוך דיונים בסדנאות ולהאזין למבחר רחב של תרגומים.",
        userFee: "לתשלום עבור השימוש",
      },
      loginMessage: {
        register: "להרשם",
        register2: "להרשם",
        login: "כניסה",
        loginWarning1: "הנך משתמש/ת במערכת כאורח/ת. כדי לעזור לנו לשמור על בטחון המערכת אנחנו מבקשים ממך ",
        loginWarning2: "ולהתחיל להשתמש במערכת בצורה בטוחה. אם כבר ביצעת הרשמה בעבר אז רק תלחץ על כפתור הכניסה.",
        popupMessage1: "שימו לב, החל מ-1 למאי 2020 לא תתאפשר יותר גישה למערכת כאורח, אנא תדאגו ",
        popupMessage2: "ועזרו לנו לשמור על מערכת בטוחה.",
      },
      galaxyApp: {
        pendingApproval: "תודה שנרשמת למערכת הערבות. הרשמתך תבדק ותאושר בימים הקרובים. לאחר מכן תוכל להשתמש במערכת.",
        terms: "תנאי השימוש במערכת ערבות",
        close: "סגור",
        verifyAccount:
          "נרשמת לערבות בהצלחה . עלינו לאמת את חשבונך עם אחד החברים שלך שיוכל לאשר שהוא מכיר אותך. אנא מלא למטה כתובת דואר אלקטרוני של חבר שיוכל לאשר אותך. אם אין לך מידע זה, תינתן לך גישה חלקית של אורח למערכת.",
        typeFriendEmail: "הכנס/י את אימייל של חברך",
        welcomeGuestForm:
          "אתה רשום כעת כאורח. כדי לקבל גישה כמשתמש רשום עלינו לאמת את חשבונך. אנא מלא כתובת הדואר האלקטרוני של חבר שיכול לאמת את ההיכרות איתך.",
        welcomeGuestPopup:
          "ברוך הבא לערבות. אתה רשום במערכת כאורח. עד האישור תקבל הרשאות גישה חלקיות: תוכל להצטרף לכל חדר, לראות מי נמצא בחדר ולצפות בשידור. לא תוכל להשתתף בסדנא או לשאול שאלות במהלך השיעור החי. אנא עזור לנו לאמת את חשבונך בהקדם האפשרי על ידי מילוי הטופס בראש העמוד.",
        welcomeGuestPopupRequested:
          "ברוך הבא לערבות. הודעה עם בקשה לאימות חשבונך נשלחה לחבר שלך . עד האישור, תקבל הרשאות חלקיות למערכת: תוכל להצטרף לכל חדר, לראות מי נמצא בחדר ולצפות בשידור. לא תוכל להשתתף בסדנא או לשאול שאלות במהלך השידור החי.",
        approveVerificationHeader:
          "שלום [UserFirstName], חבריך מבקשים שתאמת את ההיכרות איתם. אם אתה מכיר אותם ויכול לאמת את כתובת הדואר האלקטרוני שלהם, אנא סמן אותם כמאומתים ברשימה למטה כדי שיוכלו להתחיל להשתמש בערבות. אם אינך מכיר אותם סמן ברשימה התעלם:",
        approveVerificationVerify: "אשר",
        approveVerificationIgnore: "התעלם",
        approveVerificationEmail: "דואר אלקטרוני",
        requestedVerificationBadEmailPopup:
          "כתובת הדואר האלקטרוני שהזנת אינו רשום בערבות. אנא הזן כתובת דואר אלקטרוני תקין בטופס שלמעלה.",
        requestedVerificationPopup: "נשלחה לחבר הודעה עם בקשה לאימות. לאחר האימות, תקבל גישה מלאה למערכת הערבות",
        ok: "אשר",
        cancel: "בטל",
        continueAsGuest: "המשך כאורח",
        hideSelfView: "הסתר תצוגה עצמית",
        showSelfView: "הצג תצוגה עצמית"
      },
      workshop: {
        hideQuestion: "להחביא את השאלה",
        showQuestion: "להראות את השאלה",
        copyQuestion: "להעתיק את השאלה",
        manageFontSize: "להגדיר את גודל האותיות",
        inProcess: "בתהליך... זמין בשפות",
      },
      registration: {
        continueAsGuest: "המשך כאורח",
        selectCountry: "מדינה",
        groupCity: "עיר",
        selectGender: "מגדר",
        selectLanguage: "שפה",
        selectGroup: "שם העשיריה",
        telephone: "טלפון נייד",
        male: "זכר",
        female: "נקבה",
        aboutYou: "על מסגרת הלימוד שלי ב״בני ברוך״...",
        submit: "שלח לאישור",
        welcome: "ברוכים הבאים {{name}}",
        needVerifyAccount: "נרשמת בהצלחה למערכת הערבות. על מנת לאפשר לך להשתמש במערכת, עלינו לאשר את חשבונך.",
        tellAboutYou: "אנא ספר/י לנו קצת על עצמך:",
        asGuestYouCan: "כאורח, תוכל לצפות בשידור החי. לא יתאפשר לך להשתתף בסדנאות או לשאול שאלות בלייב.",
        youRegisteredAsGuest: "הנך רשום כאורח. כדי לקבל גישה מלאה למערכת, עלינו לאשר את חשבונך.",
        youRegisteredAsGuestLink: "אנא השלם את תהליך ההרשמה.",
        toComplete: "להשלים את ההרשמה",
        completed: "הרשמתך הושלמה ותאושר בקרוב. בינתיים תוכל לצפות בשידור החי.",
      },
      temp: {
        linkToCongress: "אתר הכנס",
      },
    },
  },
  ru: {
    common: {
      oldClient: {
        openChat: "Открыть чат",
        closeChat: "Закрыть чат",
        selfAudioTest: "Самопроверка аудио",
        failGeoInfo: "Ошибка GeoInfo! Вопрос будет запрещён!",
        unifiedPlanNotSupported: "WebRTC Unified Plan НЕ поддерживается",
        browserNotSupported: "Браузер не поддерживается",
        videoNotDetected: "Видео не обнаружено!",
        noInputDevices: " :: Не найден микрофон ::",
        recording: "Запись...",
        playing: "Воспроизведение...",
        networkSettingsChanged: "Потеряна связь с сервером!",
        serverStoppedReceiveOurMedia: "Сервер перестал получать ваше видео! Пожалуйста, проверьте вашу камеру.",
        serverStoppedReceiveOurAudio: "Сервер перестал получать ваше аудио! Пожалуйста, проверьте ваш микрофон.",
        maxUsersInRoom: "Достигнуто максимальное количество пользователей в этой комнате",
        error: "Ошибка: ",
        popupBlock: "Браузер блокирует наше всплывающее окно! Пожалуйста, разрешите его",
        yourName: "Введите ваше имя...",
        selectRoom: "Выберите комнату:",
        askQuestion: "Задать вопрос",
        cancelQuestion: "Отменить вопрос",
        openBroadcast: "Отобразить вещание",
        closeBroadcast: "Скрыть вещание",
        openFullScreen: "Развернуть на весь экран",
        closeFullScreen: "Выйти из полноэкранного режима",
        broadcast: "Вещание",
        layout: "Раскладка",
        mute: "Выкл",
        unMute: "Вкл",
        startVideo: "Вкл видео",
        stopVideo: "Выкл видео",
        audioMode: "Аудио режим",
        fullMode: "Полный режим",
        selectDevice: "Выберите устройство:",
        videoSettings: "Параметры видео:",
        noResultsFound: "Ничего не найдено",
        settings: "Настройки",
        vote: "Голосовать",
        streamForWorkshop: "ПОТОК ДЛЯ СЕМИНАРА",
        streamForWorkshopDescription: "(фокус-группа отключена)",
        sourceStream: "ПОТОК ОРИГИНАЛА",
        sourceStreamDescription: "(фокус-группа включена)",
        dualLnaguagesStream: "ДВУЯЗЫЧНЫЙ ПОТОК",
        dualLnaguagesStreamDescription: "(фокус-группа отключена)",
        lowQuality: "Низкое качество",
        mediumQuality: "Среднее качество",
        highQuality: "Высокое качество",
        low: "Низкое",
        medium: "Среднее",
        high: "Высокое",
        noVideo: "Без видео",
        audioOnly: "Только аудио",
        profile: "Профиль:",
        myProfile: "Мой профиль",
        myAccount: "Мои данные",
        signOut: "Выход",
        homerLimud: "Учебный материал",
        video: "Видео",
        audio: "Аудио",
        cameraQuality: "Качество камеры",
        language: "Язык",
        joinRoom: "Войти в комнату",
        drawing: "Чертежи",
        material: "Учебный материал",
        chat: "Чат",
        support: "Поддержка",
        donate: "ВНЕСТИ ВКЛАД",
        sendQuestion: "Отправить вопрос",
        user: "Пользователь",
        usefulLinks: "Полезные ссылки",
        leave: "Выйти",
        SvivaTova: "Свива Това",
        LiveGroups: "Группы онлайн",
        WorkshopQuestions: "Вопросы семинара",
        KabbalahMedia: "Каббала Медиа",
        openKliOlami: "Отобразить группы",
        closeKliOlami: "Скрыть группы",
        oldDesign: "СТАРАЯ ВЕРСИЯ",
        newDesign: "НОВАЯ ВЕРСИЯ",
        darkTheme: "Тёмная тема",
        moveToNewVersion: `Обратите внимание, что данная версия системы Арвут прекратит работу 28 февраля 2022 года. \n Пожалуйста, перейдите на новую версию.Если у вас возникли какие-либо проблемы с переходом на новую версию, обратитесь в службу поддержки`,
        hideDisplays: "Скрыть имена пользователей",
        shidurMuted: "Отключить звук трансляции",
        translationOn: "Перевод вкл.",
        translationOff: "Перевод выкл.",
      },
      settings: {
        selectCamera: "Выбрать камеру",
        selectMic: "Выбрать микрофон",
        selectSize: "Выбрать качество видео",
        userSettings: "Пользовательские настройки",
        screenName: "Отображаемое имя пользователя",
        interfaceLanguage: "Язык интерфейса",
        subtitlesSettings: "Настройки субтитров",
        subtitleLanguage: "Текст источников",
        workshopLanguage: "Вопросы семинара",
        broadcastQuality: "Качество трансляции",
        broadcastLanguage: "Язык трансляции",
        cameraSettings: "Настройки камеры",
        microphoneSettings: "Настройки микрофона",
        micSource: "Источник звука",
        cameraSource: "Источник видео",
        cameraQuality: "Качество камеры",
        helloUser: "Здравствуйте {{name}}",
        beforeConnecting: "перед соединением с Десяткой, проверьте настройки и установки устройств:",
        backToTen: "НАЗАД К ДЕСЯТКЕ",
        donationHeader: "Дорогие товарищи и подруги!",
        donationBtn: "Примите участие в акции >>",
        donationContent: `
        <p>
        Прежде чем вы войдете в комнату десятки на очередной каббалистический урок, прежде чем улыбнетесь друг другу, прочитаете вместе отрывки из первоисточников, зададите вопрос Михаэлю Лайтману и проведете семинар, мы хотим обратиться к вам с просьбой.
        </p>
        <p>
        На сегодняшний день мы распространили беспрецедентное количество ценных и уникальных материалов по всему миру, и все это благодаря вам, тысячам преданных волонтеров.
        </p>
        <p>
        Но этого недостаточно. День ото дня мир погружается во все более острый кризис во всех сферах жизни, и ему необходим контент, который может предоставить только наша организация: контент, объясняющий важность доброй связи между людьми и приближающий человечество к разрешению всех кризисных ситуаций.
        </p>
        <p>
        Сегодня, как никогда ранее, важна ваша активность, и поэтому мы приглашаем вас сделать финансовое пожертвование и принять участие в распространении нашего контента среди человечества. 
        </p>
        <p>
        Руководство ассоциации "Бней Барух - Каббала ле-ам".
        </p>`
      },
      feedback: {
        feedbackHelp: "Фидбек/Помощь",
        feedback: "Фидбек",
        help: "Помощь",
      },
      virtualChat: {
        roomChat: "Чат комнаты",
        supportChat: "Чат поддержки",
        send: "послать",
        enterMessage: "Введите сообщение",
        msgRoomInfo: "Сообщения будут видны всем подключенным участникам Десятки.",
        msgAdminInfo: "Наша команда технической поддержки свяжется с Вами здесь.",
      },
      questions: {
        enterQuestion: "У меня вопрос ...",
        userName: "Мое имя",
        galaxyRoom: "Название десятки",
        sendQuestion: "Отправить вопрос",
      },
      loginPage: {
        support: "Помощь",
        galaxy: "Арвут",
        slogan: "Группа сегодня – это ты завтра",
        regUsers: "Зарегистрированные пользователи",
        newUsers: "Новые пользователи",
        register: "Зарегистрироваться",
        guestMessage1: "Вы можете ",
        guestMessage2: "или продолжить использовать систему в качестве гостя.",
        login: "Войти",
        guest: "Продолжить как гость",
        welcomeUser: "Здравствуйте, [UserFirstName]",
        logoOurConnection: "СЕТЬ СВЯЗИ",
        logoNetwork: "МЕЖДУ НАМИ",
        intro:
          "Виртуальный дом, наша всемирная штаб-квартира - это место, построенное ассоциацией “Бней Барух - Каббала лаАм”, как всемирный образовательный центр, позволяющий учащимся находиться в интерактивной среде обучения, максимально приближенной к физическому обучению. Уроки в живом эфире, вопросы и ответы в режиме реального времени, обсуждения на семинарах - и все это в переводе на десятки языков стран мира с целью нашего общего духовного продвижения.",
        userFee: "Внести абонентскую плату",
      },
      loginMessage: {
        register: "зарегистрироваться",
        register2: "зарегистрируйтесь",
        login: "ВОЙТИ",
        loginWarning1:
          "Вы используете систему в качестве гостя. Чтобы помочь нам обеспечить безопасность системы, просим вас ",
        loginWarning2: "и начать безопасно ее использовать. Если вы уже сделали это - просто нажмите на кнопку Войти.",
        popupMessage1: "Обратите внимание, что с 1 мая 2020 года система больше не будет разрешать гостевой доступ, ",
        popupMessage2: "и помогите нам поддерживать безопасную систему.",
      },
      galaxyApp: {
        pendingApproval:
          "Спасибо за регистрацию в Arvut. Ваша учетная запись будет проверена в ближайшие несколько дней. Вы сможете использовать систему впоследствии.",
        verifyAccount:
          "Вы успешно записаны в Arvut. Нам необходимо подтвердить Ваш аккаунт  через одного из Ваших товарищей, который знаком с Вами и готов это подтвердить. Запишите, пожалуйста, ниже адрес электронной почты товарища для подтверждения. Если у Вас нет такой информации,  то Вам будет предоставлено частичное использование системы в качестве гостя.",
        typeFriendEmail: "Запишите, пожалуйста, электронный адрес товарища",
        welcomeGuestForm:
          "На данный момент Вы записаны в качестве гостя. Чтобы получить вход в систему на правах обычного пользователя, нам необходимо подтвердить Ваш аккаунт. Пожалуйста, напишите электронный адрес Вашего товарища, который может подтвердить, что знаком с Вами.",
        welcomeGuestPopup:
          "Добро пожаловать в Arvut. Вы записаны в системе в качестве гостя. До полного разрешения, Вы получите право частичного использования системы: Вы сможете присоединиться к любой комнате, видеть кто находится в комнате  и смотреть трансляцию. Вы не сможете участвовать в семинаре и задавать вопросы в прямом эфире. Пожалуйста, помогите нам подтвердить Ваш аккаунт, как можно быстрее, с помощью заполнения анкеты в верху этой страницы.",
        welcomeGuestPopupRequested:
          "Добро пожаловать в Arvut. Вашему товарищу отправлено сообщение с просьбой о подтверждении Вашего аккаунта. До подтверждения им данных, Вы получите право частичного использования  системы: Вы сможете присоединиться к любой комнате, видеть кто находится в комнате и смотреть трансляцию. Вы не сможете участвовать в семинаре и задавать вопросы во время прямой трансляции.",
        approveVerificationHeader:
          'Здравствуйте, [UserFirstName]. Ваши друзья просят Вас подтвердить то, что они вам знакомы. Если знакомы и вы узнаёте их мейл, то отметьте их в колонке "подтвердить" и они смогут начать пользоваться Arvut. А если нет, то отметьте их в колонке "игнорировать":',
        approveVerificationVerify: "Подтвердить",
        approveVerificationIgnore: "Игнорировать",
        approveVerificationEmail: "Эл. почта",
        requestedVerificationBadEmailPopup:
          "Адрес электронной почты, который Вы ввели, не зарегистрирован в системе Arvut. Пожалуйста, введите точный адрес электронной почты на верхней части страницы.",
        requestedVerificationPopup:
          "Вашему товарищу выслано сообщение  с просьбой о подтверждении. После подтверждения Вы получите полный доступ к системе Arvut.",
        ok: "Применить",
        cancel: "Отменить",
        continueAsGuest: "Продолжить как гость",
        terms: "Условия использования системы Арвут",
        close: "Закрыть",
        hideSelfView: "Скрыть свое видео",
        showSelfView: "Отобразить свое видео"
      },
      workshop: {
        hideQuestion: "Скрыть вопрос",
        showQuestion: "Показать вопрос",
        copyQuestion: "Копировать вопрос",
        manageFontSize: "Изменить размер шрифта",
        inProcess: "В процессе... Доступно на",
      },
      registration: {
        continueAsGuest: "Продолжить как гость",
        selectCountry: "Страна",
        groupCity: "Город",
        selectGender: "Пол",
        selectLanguage: "Язык",
        selectGroup: "Название десятки",
        telephone: "Мобильный телефон",
        male: "мужчина",
        female: "женщина",
        aboutYou: "О моих учебных рамках в Бней Барухе",
        submit: "ОТПРАВИТЬ НА УТВЕРЖДЕНИЕ",
        welcome: "Добро пожаловать, {{name}}",
        needVerifyAccount:
          "Вы успешно зарегистрировались в системе Арвут. Чтобы предоставить вам полный доступ, нам необходимо будет подтвердить вашу учетную запись.",
        tellAboutYou: "Расскажите, пожалуйста, о себе:",
        asGuestYouCan:
          "В качестве гостя вы сможете смотреть трансляцию. Вы не сможете участвовать в семинарах или задавать вопросы в прямом эфире.",
        youRegisteredAsGuest:
          "Вы зарегистрированы как гость. Чтобы получить полный доступ, нам необходимо подтвердить вашу учетную запись. ",
        youRegisteredAsGuestLink: "Пожалуйста, завершите регистрацию.",
        toComplete: "ЗАВЕРШИТЬ РЕГИСТРАЦИЮ",
        completed: "Ваша регистрация завершена и скоро будет проверена. А пока вы можете смотреть трансляцию.",
      },
      temp: {
        linkToCongress: "сайт конгресса",
      },
    },
  },
};
